import './scss/style.scss'
import $ from 'jquery'
import ScrollMagic from 'scrollmagic'
import Fullpage from 'fullpage.js'

$(function () {
  $('#page').fullpage({
    onLeave: function(index, nextIndex, direction) {
      if (index === 1) {
        $('#logo').css('opacity', 1);
        $('#animation_container').fadeOut();
      }
    }
  });
  var controller = new ScrollMagic.Controller({
    globalSceneOptions: {
      triggerHook: 'onLeave'
    }
  });

  // get all slides
  var slides = document.querySelectorAll("section.scrollani");

  // create scene for every slide
  for (var i=0; i<slides.length; i++) {
    new ScrollMagic.Scene({
        triggerElement: slides[i]
      })
      .setPin(slides[i])
      //.addIndicators() // add indicators (requires plugin)
      .addTo(controller);
  }

  $('#imprint').on('click', function(event) {
    $('#imprintModal').slideDown();
  });

  $('#contact').on('click', function(event) {
    $('#contactModal').slideDown();
  });

  $('.closeBtn').on('click', function(event) {
    $('#' + $(this).data('id')).slideUp();
  });
});
